import { Vumeter } from "./Vumeter";
import { Loader } from "./Loader";

type Props = {
  calling: boolean;
  toggleCalling: () => void;
  mediaDeviceList: MediaDeviceInfo[];
  toggleAudioDeviceId: (event: HTMLSelectElement) => void;
  pin: string;
  togglePin: (pin: string) => void;
  savePin: boolean;
  toggleSavePin: (savePin: boolean) => void;
  audioDeviceId: string;
  pinInputClass: string;
  notConnection: boolean;
  errorMessage: string | null;
  meterValue: number;
};

export function JoinCall({
  calling,
  toggleCalling,
  mediaDeviceList,
  toggleAudioDeviceId,
  pin,
  togglePin,
  savePin,
  toggleSavePin,
  audioDeviceId,
  pinInputClass,
  notConnection,
  errorMessage,
  meterValue
}: Props) {
  return (
    <>
      <h3 className="title">
        Please enter your six digit pin and click the ‘Call’ button
      </h3>
      <div className="action-items">
        <div className="pin-actions-container">
          <input
            className={pinInputClass}
            type="text"
            name="input_pin"
            placeholder="PIN e.g. 123456"
            value={pin}
            maxLength={6}
            onInput={(e) => {
              let element = e.currentTarget;
              togglePin(element.value);
            }}
          />
          {errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
          <div className="microphone-selection">
            <label>Choose your microphone</label>
            <select
              onChange={(e) => {
                toggleAudioDeviceId(e.target);
              }}
              value={audioDeviceId}
            >
              {mediaDeviceList.map((microphone, index) => (
                <option key={index} value={microphone.deviceId}>
                  {microphone.label}
                </option>
              ))}
            </select>
          </div>
          <div className="call-options">
            <div className="save-pin-group">
              <input
                type="checkbox"
                onChange={(e) => {
                  let element = e.currentTarget;
                  toggleSavePin(element.checked);
                }}
                checked={savePin}
              />
              <label htmlFor="save_pin">Save this PIN for next time</label>
            </div>
            <button
              className="btn call"
              onClick={toggleCalling}
              disabled={calling || notConnection ? true : false}
            >
              {calling ? (
                <>
                  <Loader />
                  Calling
                </>
              ) : (
                <>
                  <i className="fas fa-phone-alt" style={{ marginRight: 5 }} />
                  Call
                </>
              )}
            </button>
          </div>
        </div>
        <div className="vumeter-container">
          <Vumeter 
            meterValue={meterValue}
          />
        </div>
      </div>
    </>
  );
}
