import MicSelectMeter from "../assets/images/mic-select-meter.svg";

type Props = {
  meterValue: number;
};

export function Vumeter({meterValue}: Props) {
  return (
    <>
      <div className="meter">
        <img
          src={MicSelectMeter}
          alt="Mic Select Meter"
          style={{ position: "absolute", zIndex: 2 }}
        />
        <div className="meter-fill" style={{ height: meterValue+"%" }} />
      </div>
      <div className="mic-icon">
        <i
          className="fas fa-microphone"
          style={{ width: 17, textAlign: "center" }}
        />
      </div>
    </>
  );
}
