import { Vumeter } from "./Vumeter";
import { Modal } from "./Modal";
type Props = {
  pin: string;
  endCall: () => void;
  mediaDeviceList: MediaDeviceInfo[];
  toggleMute: () => void;
  toggleDtmfTone: (value: string) => void;
  mute: boolean;
  audioDeviceId: string;
  dtmfTone: string;
  showVolumeIndicator: boolean;
  toggleShowVolumeIndicator: () => void;
  notConnection: boolean;
  globalRandomNumber: { lastPart: string };
  toggleAudioDeviceIdReInvite: (event: HTMLSelectElement) => void;
  modalRef: React.RefObject<HTMLDivElement>;
  spanRef: React.RefObject<HTMLSpanElement>;
  showModal: () => void;
  initCall: () => void;
  changeCalling: () => void;
  meterValue: number;
};
export function InCall({
  pin,
  endCall,
  mediaDeviceList,
  toggleMute,
  toggleDtmfTone,
  mute,
  audioDeviceId,
  dtmfTone,
  showVolumeIndicator,
  toggleShowVolumeIndicator,
  notConnection,
  globalRandomNumber,
  toggleAudioDeviceIdReInvite,
  modalRef,
  spanRef,
  showModal,
  initCall,
  changeCalling,
  meterValue
}: Props) {
  return (
    <>
      <h2 className="title-incall">
        Connected with PIN # <span id="pin_number">{pin}</span>
      </h2>
      <hr />
      <div className="dialpad">
        <div className="keypad-wrapper">
          <div className="keypad">
            <div className="btn-actions">
              <div className="btn-wrapper">
                <button onClick={toggleMute} disabled={notConnection}>
                  {mute ? (
                    <i className="fas fa-microphone-slash text-danger" />
                  ) : (
                    <i className="fas fa-microphone text-success" />
                  )}
                </button>
                <label id="lbl_mute">{mute ? "Unmute" : "Mute"}</label>
              </div>
              <div className="btn-wrapper">
                <button onClick={endCall} disabled={notConnection}>
                  <i className="fas fa-phone-slash text-danger" />
                </button>
                <label>Hang Up</label>
              </div>
              <div className="btn-wrapper">
                <button
                  onClick={toggleShowVolumeIndicator}
                  className={showVolumeIndicator ? "active" : ""}
                  disabled={notConnection}
                >
                  <i className="fas fa-volume-up" />
                </button>
                <label>Volume</label>
              </div>
            </div>
            <div className="volume-group">
              <input
                type="range"
                id="range_volume"
                defaultValue={100}
                onChange={(e) => {
                  let element = e.currentTarget;
                  let remoteAudio = document.querySelector(
                    "#remoteAudio"
                  ) as HTMLAudioElement;
                  remoteAudio.volume = parseFloat(element.value) / 100;
                }}
                hidden={!showVolumeIndicator}
              />
            </div>
            <div id="keypad">
              <div>
                <button
                  className={dtmfTone === "1" ? "active" : ""}
                  onClick={() => {
                    toggleDtmfTone("1");
                  }}
                  disabled={notConnection}
                >
                  1
                </button>
                <button
                  className={dtmfTone === "2" ? "active" : ""}
                  onClick={() => {
                    toggleDtmfTone("2");
                  }}
                  disabled={notConnection}
                >
                  2
                </button>
                <button
                  className={dtmfTone === "3" ? "active" : ""}
                  onClick={() => {
                    toggleDtmfTone("3");
                  }}
                  disabled={notConnection}
                >
                  3
                </button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className={dtmfTone === "4" ? "active" : ""}
                  onClick={() => {
                    toggleDtmfTone("4");
                  }}
                  disabled={notConnection}
                >
                  4
                </button>
                <button
                  className={dtmfTone === "5" ? "active" : ""}
                  onClick={() => {
                    toggleDtmfTone("5");
                  }}
                  disabled={notConnection}
                >
                  5
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="volume-meter-wrapper">
          <Vumeter 
            meterValue={meterValue}
          />
        </div>
      </div>
      <div className="microphone-selection">
        <label>Choose your microphone</label>
        <select
          onChange={(e) => {
            toggleAudioDeviceIdReInvite(e.target);
            showModal();
          }}
          value={audioDeviceId}
        >
          {mediaDeviceList.map((microphone, index) => (
            <option key={index} value={microphone.deviceId}>
              {microphone.label}
            </option>
          ))}
        </select>
      </div>
      <div className="dialpad-footer">
        <span className="upper-text">
          Last 4 digits of your webphone number:
          <span
            className="text-success"
            id="last_four_digits"
            style={{ marginLeft: 5 }}
          >
            {globalRandomNumber.lastPart}
          </span>
        </span>
        <span className="lower-text">
          (You may need it when you login to Meeting using a shared PIN)
        </span>
      </div>
      <Modal modalRef={modalRef} spanRef={spanRef}>
        <div>
          <h2>
            This action will cause a restart of your call, do you wish to
            continue?
          </h2>
          <div>
            <button
              onClick={() => {
                changeCalling();
                let modal = modalRef.current;
                if (modal) modal.style.display = "none";
                endCall();
                setTimeout(() => {
                  initCall();
                }, 2000);
              }}
              className="accept-ntn"
            >
              Yes
            </button>
            <button
              onClick={() => {
                let modal = modalRef.current;
                if (modal) modal.style.display = "none";
              }}
              className="decline-btn"
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
