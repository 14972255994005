export function getConnectedDevices(
  type: string,
  callback: (value: MediaDeviceInfo[]) => void
) {
  navigator.mediaDevices.enumerateDevices().then((devices) => {
    const filtered = devices.filter((device) => device.kind === type);
    callback(filtered);
  });
}

export function getUserMedia(
  constraints: { audio: boolean; video: boolean }, 
  resolve: () => void,
  reject: () => void
) {

  navigator.mediaDevices
    .getUserMedia(constraints)
    .then(() => {
      resolve();
    })
    .catch((error) => {
      console.error("Error accessing media devices.", error);
      reject();
    });
}

export function randomNumber() {
  var number: string = Math.floor(Math.random() * (1000000 + 1)).toString();
  number += "";
  if (number.length > 6) {
    return { randomNumber: number, lastPart: number.substring(3, 7) };
  } else {
    let numRest = 7 - number.length;
    let strRest = "";
    for (let i = 0; i < numRest; i++) {
      strRest += "0";
    }
    let newNumber = strRest + number;
    return { randomNumber: newNumber, lastPart: newNumber.substring(3, 7) };
  }
}

export function setCookie(cname: string, cvalue: string, exdays: number) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cname +
    "=" +
    cvalue +
    ";" +
    expires +
    ";domain=.maestroconference.com ;path=/";
}

export function getCookie(cname: string) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
