import { useState, useEffect, createRef } from "react";
import { DialerBox } from "./components/DialerBox";
import { Header } from "./components/Header";
import { JoinCall } from "./components/JoinCall";
import { InCall } from "./components/InCall";
import { getUserMedia, getConnectedDevices, getCookie, setCookie, randomNumber } from "./utils/utils";
import { UserAgent, Web } from "sip.js";

var servers: any = [];
var sortServerDetails: any = [];
var launchServer = false;

// FROM ENVIRONMENT
// router server
const routeServer = process.env.REACT_APP_ROUTER;
const routerServerTarget = routeServer+"@"+routeServer+":5080";
// DID 
const DID = process.env.REACT_APP_DID;
// Display Name
const displayName = process.env.REACT_APP_DISPLAY_NAME;

var audioContext = null; //new window.AudioContext();
var analyser:any = null; //audioContext.createAnalyser();
var microphone:any = null; //audioContext.createMediaStreamSource(stream);
var javascriptNode:any = null; //audioContext.createScriptProcessor(2048, 1, 1);
var callEnded = false;
var callStarting = false;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const pinUrl = urlParams.get("pin");

// adding query params for testing purposes
const testServer = urlParams.get("testServer");

var serversDetails = JSON.parse(getAllDropletsInfo()); // API call to the bash script which contains count and server details
var lastUsedServer = serversDetails.lastUsedServer;

if(serversDetails != null || serversDetails !== undefined){
  // if testServer params is available then only use the following server
  if(testServer == "true"){
    let serverToAdd: any = {};
    serverToAdd["id"] = 0;
    serverToAdd["target"] = routerServerTarget;
    serverToAdd["wss"] = "wss://vultr_wrtcfs1.maestroconference.com:7443";
    serverToAdd["uri"] = "vultr_wrtcfs1.maestroconference.com";
    servers.push(serverToAdd);
  } else {
    // complete function which is picking server with logics 
    pickServerForConnectingCall(serversDetails);
  }

} else { // if API is not working (just in case), must add the webphone 1 server details
  // if testServer params is available then only use the following server
  if(testServer == "true"){
    let serverToAdd: any = {};
    serverToAdd["id"] = 0;
    serverToAdd["target"] = routerServerTarget;
    serverToAdd["wss"] = "wss://vultr_wrtcfs1.maestroconference.com:7443";
    serverToAdd["uri"] = "vultr_wrtcfs1.maestroconference.com";
    servers.push(serverToAdd);
  } else {
    let serverToAdd: any = {};
    serverToAdd["id"] = 0;
    serverToAdd["target"] = routerServerTarget;
    serverToAdd["wss"] = "wss://wrtcfs1.maestroconference.com:7443";
    serverToAdd["uri"] = "wrtcfs1.maestroconference.com";
    servers.push(serverToAdd);
  }
}

const server = servers[Math.floor(Math.random() * servers.length)];

var intervalID: any = null;

function App() {
  //Variables for view-level operation and control
  const [pin, setPin] = useState(
    pinUrl
      ? pinUrl
      : localStorage.getItem("pin") === null
      ? ""
      : localStorage.getItem("pin") + ""
  );
  const [savePin, setSavePin] = useState(
    localStorage.getItem("savePin") === "true" ? true : false
  );
  const modalRef = createRef<HTMLDivElement>();
  const spanRef = createRef<HTMLSpanElement>();
  const [mute, setMute] = useState(false);
  const [dtmfTone, setDtmfTone] = useState("");
  const [statusCall, setStatusCall] = useState(false);
  const [calling, setCalling] = useState(false);
  const [audioDeviceId, setAudioDeviceId] = useState("default");
  const [mediaDeviceList, setMediaDeviceList] = useState<MediaDeviceInfo[]>([]);
  const [pinInputClass, setPinInputClass] = useState("input-pin");
  const [showVolumeIndicator, setShowVolumeIndicator] = useState(false);
  const [notConnection, setNotConnection] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [meterValue, setMeterValue] = useState(0);

  const [globalRandomNumber] = useState(() => {
    let webphoneUserAni = getCookie("webphoneUser");
    if (webphoneUserAni) {
      return {
        randomNumber: webphoneUserAni,
        lastPart: webphoneUserAni.substring(3, 7),
      };
    } else {
      let localGlobalRandomNumber = randomNumber();
      setCookie("webphoneUser", localGlobalRandomNumber.randomNumber, 365);
      return localGlobalRandomNumber;
    }
  });

  const changeCalling = () => {
    setCalling(true);
  };

  const showModal = () => {
    let modal = modalRef.current;
    if (modal) modal.style.display = "block";
  };

  // Destination URI
  let target = `sip:${DID}${pin}${server.target}`;

  //Variables for the operation of SIP.JS
  const [uri] = useState(
    UserAgent.makeURI(
      `sip:sipjs_${globalRandomNumber.randomNumber}@${server.uri}`
    )
  );

  const [simpleUserDelegate] = useState<Web.SimpleUserDelegate>({
    onCallCreated: (): void => {
      console.log(`[${displayName}] Call created`);
    },
    onCallAnswered: (): void => {
      console.log(`[${displayName}] Call answered`);
      setCalling(false);
      setStatusCall(true);
      sendMessageToSW({
        signal: "CONNECTED",
        value: "CONNECTED",
      });
      callStarting = true;
      let mediaState = document.querySelector("#media_state") as HTMLDivElement;
      mediaState.dataset.value = "true";
      setErrorMessage(null);
    },
    onCallHangup: (): void => {
      console.log(`[${displayName}] Call hangup`);
      defaultValues();
    },
    onServerDisconnect: (error: Error): void => {
      console.log(`[${displayName}] Server Error!!! ${error}`);
      setErrorMessage("Error connecting the call, please try again.");
      defaultValues();
    },
    onMessageReceived: (message: string): void => {
      console.log(`message: ${message}`);
    },
  });

  const defaultValues = () => {
    setCalling(false);
    setStatusCall(false);
    setMute(false);
    let remoteAudio = document.querySelector(
      "#remoteAudio"
    ) as HTMLAudioElement;
    remoteAudio.volume = 1;
    setDtmfTone("");
    setShowVolumeIndicator(false);
    sendMessageToSW({ signal: "DISCONNECTED", value: "DISCONNECTED" });
    callStarting = false;
    let mediaState = document.querySelector("#media_state") as HTMLDivElement;
    mediaState.dataset.value = "false";
  };

  const [simpleUserOptions] = useState<Web.SimpleUserOptions>({
    delegate: simpleUserDelegate,
    media: {
      remote: {
        audio: document.querySelector("#remoteAudio") as HTMLAudioElement,
      },
    },
    userAgentOptions: {
      logLevel: "log",
      displayName,
      uri,
    },
  });

  // SimpleUser construction
  //It is enclosed in an arrow function to avoid creating instances of the class for each render.
  const [simpleUser] = useState<Web.SimpleUser>(() => {
    return new Web.SimpleUser(server.wss, simpleUserOptions);
  });

  const toggleShowVolumeIndicator = () => {
    setShowVolumeIndicator(!showVolumeIndicator);
  };

  const toggleCalling = () => {
    if (pin.length === 6) {
      setCalling(!calling);
      initCall();
    } else {
      setPinInputClass("input-pin-danger");
      setErrorMessage("Invalid PIN");
    }
  };

  const toggleAudioDeviceIdReInvite = (event: HTMLSelectElement) => {

    let audioDeviceId = event.value;
    setAudioDeviceId(audioDeviceId);

  };

  const toggleAudioDeviceId = (event: HTMLSelectElement) => {

    setAudioDeviceId(event.value);
    setVolumeMeterFunction(event.value);

  };

  const setVolumeMeterFunction = (deviceId:string) => {

    // work for volumne meter 
    const constraints: any = {audio: {autoGainControl: true, echoCancellation: true, noiseSuppression: true, deviceId: {exact: deviceId}}};
    var micDeviceCheckerTimer: any = null;
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {

      if (javascriptNode) {
        javascriptNode.disconnect();
      }
      if (analyser) {
        analyser.disconnect();
      }
      if (microphone) {
        microphone.mediaStream.getTracks().forEach((track:any) => track.stop())
        if (microphone.context.state !== 'closed') {
          microphone.context.close();
        }
        microphone.disconnect();
      }

      audioContext = new window.AudioContext();
      analyser = audioContext.createAnalyser();
      microphone = audioContext.createMediaStreamSource(stream);
      javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
      
      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 1024;

      javascriptNode.connect(audioContext.destination);
      analyser.connect(javascriptNode);
      microphone.connect(analyser);

      javascriptNode.onaudioprocess = () => {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        let values = 0;

        const length = array.length;
        for (let i = 0; i < length; i++) {
          values += (array[i]);
        }

        const average = values / length;
        let value = (Math.round(average) * 2)
        value = value > 100 ? 100 : value;
        setMeterValue(value);
      }
    })
  }

  const togglePin = (pin: string) => {
    if (!/\D/.test(pin.charAt(pin.length - 1)) && pin.length < 7) {
      setPinInputClass("input-pin");
      setPin(pin);
      setErrorMessage(null);
    }
  };

  const toggleSavePin = (savePin: boolean) => {
    setSavePin(savePin);
    if (savePin && pin.length === 6) {
      localStorage.setItem("pin", pin);
      localStorage.setItem("savePin", "true");
    } else {
      localStorage.removeItem("pin");
      localStorage.removeItem("savePin");
    }
    if (pin.length < 6) {
      setPinInputClass("input-pin-danger");
      setSavePin(false);
    }
  };

  const toggleMute = () => {
    if (mute) {
      simpleUser.unmute();
      clearInterval(intervalID);
    } else {
      simpleUser.mute();
      // a hack for sending mute and unmute to stabilize connection
      intervalID = setInterval(() => {
        simpleUser.unmute();
        simpleUser.mute();
      }, 120000); // checking on every 2 minutes
    }
    setMute(!mute);
  };

  const toggleDtmfTone = (value: string) => {
    setDtmfTone(value);
    simpleUser.sendDTMF(value).then(() => {
      console.log(value);
    });
  };

  const initCall = () => {
    /*
    * Try a hack, connect the socket on call button and then connect with call
    */
    simpleUser
      .connect()
      .then(() => {
        console.log("¡Websocket connected!");

        /*
        * Connect with the call after socket connection
        */
        if (!callStarting) {
          callStarting = true;
          simpleUser
            .call(target, {
              inviteWithoutSdp: false,
              sessionDescriptionHandlerOptions: {
                constraints: {
                  audio: { deviceId: audioDeviceId },
                  video: false,
                },
              },
              sessionDescriptionHandlerOptionsReInvite: {
                constraints: {
                  audio: { deviceId: audioDeviceId },
                  video: false,
                },
              },


            }) // exception for making / placing call 
            .catch((error: Error) => {
              console.error(`[${simpleUser.id}] failed to place call`);
              console.error(error);
            });

            // saving last used server in a file for some work
            saveUsedServerInfo(server.uri);
        }

      }) // exception for connect socket
      .catch((error: Error) => {
        console.error(`[${simpleUser.id}] failed to connect`);
        setErrorMessage("Error connecting to the provided websocket");
        console.error(error);
      });

  };

  const endCall = () => {
    /*
    * Removed the hangup() temporarily to check if working fine and called the disconnect directly
    */
    simpleUser
      .disconnect()
      .then(() => {
        callStarting = false;
        console.log("Websocket disconnected and call hungup")
      })
      .catch((error: Error) => {
        console.error(`[${simpleUser.id}] failed to disconnect`);
        console.error(error);
        alert("Failed to disconnect.\n" + error);
      });

  };

  useEffect(() => {
    getUserMedia(
      { audio: true, video: false },
      () => {
        getConnectedDevices("audioinput", (listMicrophones) => {
          setMediaDeviceList(listMicrophones);
        });
        /*
        * if url has ?pin query param then connect the call automatically (work for social webinar)
        */
        if(pinUrl != null){
          initCall();
        }
      },
      () => {}
    );

    // on page load, make the volume meter work
    setVolumeMeterFunction(audioDeviceId);

    /*
    * if url has ?pin query param then connect the call automatically (work for social webinar)
    */
    // if(pinUrl != null){
      // initCall();
    // }

    if (!window.navigator.onLine) {
      let snackbar = document.querySelector("#snackbar") as HTMLElement;
      snackbar.innerHTML =
        '<span class="text-danger">Offline. Please check your internet connection!</span>';
      snackbar.className = "show";
      setNotConnection(true);
    }

    window.addEventListener("offline", function (e) {
      let snackbar = document.querySelector("#snackbar") as HTMLElement;
      snackbar.innerHTML =
        '<span class="text-danger">Offline. Please check your internet connection!</span>';
      snackbar.className = "show";
      setNotConnection(true);
      sendMessageToSW({
        signal: "OFFLINE",
      });
    });

    window.addEventListener("online", function (e) {
      let snackbar = document.querySelector("#snackbar") as HTMLElement;
      snackbar.innerHTML = '<span class="text-success">Online</span>';
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
      setNotConnection(false);
      sendMessageToSW({
        signal: "ONLINE",
      });
    });
  }, [simpleUser]);

  useEffect(() => {
    navigator.mediaDevices.addEventListener("devicechange", () => {
      getConnectedDevices("audioinput", (microphones) => {
        setMediaDeviceList(microphones);
      });
    });
    window.addEventListener(
      "message",
      (e) => {
        var message = e.data;
        switch (message.signal) {
          case "StartCall":
            setPin(message.pin);
            setTimeout(() => {
              if (!callStarting) {
                simpleUser
                  .call(target, {
                    inviteWithoutSdp: false,
                    sessionDescriptionHandlerOptions: {
                      constraints: {
                        audio: true,
                        video: false,
                      },
                    },
                  })
                  .catch((error: Error) => {
                    console.error(`[${simpleUser.id}] failed to place call`);
                    console.error(error);
                  });
              }
            }, 5000);
            break;
          case "EndCall":
            if (!callEnded) {
              endCall();
              callEnded = !callEnded;
            }
            break;
          case "Mute":
            if (mute) {
              simpleUser.unmute();
            } else {
              simpleUser.mute();
            }
            setMute(!mute);
            break;
          case "Volume":
            let remoteAudio = document.querySelector(
              "#remoteAudio"
            ) as HTMLAudioElement;
            remoteAudio.volume = parseFloat(message.volume) / 100;
            break;
          case "ChangeAudioInput":
            break;
        }
      },
      false
    );

    // settig some UI on the basis of server
    if(launchServer){
      setErrorMessage("Our servers are full, kindly retry after 1 minute");
      defaultValues();
    }

  });
  return (
    <div className="container">
      <Header />
      <main>
        <DialerBox>
          {statusCall ? (
            <InCall
              pin={pin}
              endCall={endCall}
              mediaDeviceList={mediaDeviceList}
              toggleMute={toggleMute}
              toggleDtmfTone={toggleDtmfTone}
              mute={mute}
              audioDeviceId={audioDeviceId}
              dtmfTone={dtmfTone}
              showVolumeIndicator={showVolumeIndicator}
              toggleShowVolumeIndicator={toggleShowVolumeIndicator}
              notConnection={notConnection}
              globalRandomNumber={globalRandomNumber}
              toggleAudioDeviceIdReInvite={toggleAudioDeviceIdReInvite}
              modalRef={modalRef}
              spanRef={spanRef}
              showModal={showModal}
              initCall={initCall}
              changeCalling={changeCalling}
              meterValue={meterValue}
            />
          ) : (
            <JoinCall
              calling={calling}
              toggleCalling={toggleCalling}
              mediaDeviceList={mediaDeviceList}
              toggleAudioDeviceId={toggleAudioDeviceId}
              pin={pin}
              togglePin={togglePin}
              savePin={savePin}
              toggleSavePin={toggleSavePin}
              audioDeviceId={audioDeviceId}
              pinInputClass={pinInputClass}
              notConnection={notConnection}
              errorMessage={errorMessage}
              meterValue={meterValue}
            />
          )}
        </DialerBox>
      </main>
      <footer>© MaestroConference</footer>
      <a
        className="btn alternative-webphone"
        href={
          pin
            ? `https://webphone2.maestroconference.com/?pin=${pin}`
            : `https://webphone2.maestroconference.com`
        }
      >
        Try the alternative Webphone
      </a>
    </div>
  );
}

function sendMessageToSW(message: any) {
  if(window != null && window.top != null){
    window.top.postMessage(message, "*");
  }
}

function pickServerForConnectingCall(serversDetails: any){

  // converting object to array for sorting purposes
  for (var key in serversDetails) {
    if(key == "lastUsedServer") { continue; } // ignore the iteration of lastUsedServer
    if (serversDetails.hasOwnProperty(key)) {
      let dropletId = serversDetails[key].split(" ")[0];
      let url = serversDetails[key].split(" ")[1];
      let ip = serversDetails[key].split(" ")[2];
      let status = serversDetails[key].split(" ")[3];
      let date = serversDetails[key].split(" ")[4];
      let callersCount = serversDetails[key].split(" ")[5];

      let serverToAdd: any = {};
      serverToAdd["url"] = url;
      serverToAdd["count"] = callersCount;
      sortServerDetails.push(serverToAdd);
    }
  }

  // sort the servers on the basis of callers count
  var sortedServers = sortServerDetails.slice(0);
  sortedServers.sort((a:any, b:any) => {
      return a.count - b.count;
  });
  
  // checking if server 1 is already filled with 15 callers if not then use this first
  // for (let i = 0; i < sortedServers.length; i++){

  //   let serversCount = sortedServers.length;
  //   let url = sortedServers[i].url;
  //   let callersCount = sortedServers[i].count;

  //   if(url == "wrtcfs1.maestroconference.com") {
  //     if(parseInt(callersCount) < 15){
  //       let serverToAdd: any = {};
  //       serverToAdd["id"] = 0;
  //       serverToAdd["target"] = routerServerTarget;
  //       serverToAdd["wss"] = "wss://wrtcfs30.maestroconference.com:7443";
  //       serverToAdd["uri"] = "wrtcfs30.maestroconference.com";
  //       servers.push(serverToAdd);
  //       break;
  //     } else {
  //       break;
  //     }
  //   }

  // }

  /*
  * means API has more server but no server is lying on above conditions,
    just do a basic check and if less than 50 to any server then connect to that server,
    if still all servers are > 50 callers count then we do have to wait --> TODO: will do this work later
  */
  if(servers.length == 0){

    // processing the server picking 
    for (let i = 0; i < sortedServers.length; i++){

      let serversCount = sortedServers.length;
      let url = sortedServers[i].url;
      let callersCount = sortedServers[i].count;

      if(serversCount > 1){
        // soft condition, if servers are used with last callers, use some other server
        if(lastUsedServer == url) {
          continue;
        }
        // initially check with 15 callers on each server, if less than fine to connect otherwise skip to next server
        if(parseInt(callersCount) < 15){
          let serverToAdd: any = {};
          serverToAdd["id"] = 0;
          serverToAdd["target"] = routerServerTarget;
          serverToAdd["wss"] = "wss://"+url+":7443";
          serverToAdd["uri"] = url;
          servers.push(serverToAdd);            
          break;
        } else if(parseInt(callersCount) >= 15){ // if this server count is greater than check another server
          continue;
        }
      } else if (serversCount == 1){ // if we have single server open, mendatory to connect with that
        
        let serverToAdd: any = {};
        serverToAdd["id"] = 0;
        serverToAdd["target"] = routerServerTarget;
        serverToAdd["wss"] = "wss://"+url+":7443";
        serverToAdd["uri"] = url;
        servers.push(serverToAdd);
      }

    }

  }

  // last try to handle upto 25 callers which ever server is available
  if(servers.length == 0){

    // processing the server picking 
    for (let i = 0; i < sortedServers.length; i++){

      let serversCount = sortedServers.length;
      let url = sortedServers[i].url;
      let callersCount = sortedServers[i].count;

      if(serversCount > 1){

        // check with 25 callers on each server, if less than fine to connect otherwise skip to next server
        if(parseInt(callersCount) < 25){
          let serverToAdd: any = {};
          serverToAdd["id"] = 0;
          serverToAdd["target"] = routerServerTarget;
          serverToAdd["wss"] = "wss://"+url+":7443";
          serverToAdd["uri"] = url;
          servers.push(serverToAdd);            
          break;
        } else if(parseInt(callersCount) >= 25){ // if this server count is greater than check another server
          // if the last iteration is coming and still not server is assigned
          if(serversCount == i+1 && servers.length == 0){
            launchServer = true;
            
            // handling only if accidently they try to connect
            let serverToAdd: any = {};
            serverToAdd["id"] = 0;
            serverToAdd["target"] = routerServerTarget;
            serverToAdd["wss"] = "wss://wrtcfs1.maestroconference.com:7443";
            serverToAdd["uri"] = "wrtcfs1.maestroconference.com";
            servers.push(serverToAdd);
          }
          continue;
        }
      } else if (serversCount == 1){ // if we have single server open, mendatory to connect with that
        
        let serverToAdd: any = {};
        serverToAdd["id"] = 0;
        serverToAdd["target"] = routerServerTarget;
        serverToAdd["wss"] = "wss://"+url+":7443";
        serverToAdd["uri"] = url;
        servers.push(serverToAdd);
      }

    }

  }

}

function getAllDropletsInfo(){

  let url : string = process.env.REACT_APP_LIST || "";
  var request = new XMLHttpRequest();
  
  request.open('GET', url, false);  // `false` makes the request synchronous
  request.send(null);

  return request.response;
}

function saveUsedServerInfo(server: any){

  let url : string = process.env.REACT_APP_USED || "";
  var request = new XMLHttpRequest();
  
  request.open('GET', url+'?server='+server, true);  // `false` makes the request synchronous
  request.send(null);

  return request.response;
}

export default App;
