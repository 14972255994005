import { useEffect } from "react";
import ReactDOM from "react-dom";

type Props = {
  children: JSX.Element;
  modalRef: React.RefObject<HTMLDivElement>;
  spanRef: React.RefObject<HTMLSpanElement>;
};

export function Modal({ modalRef, spanRef, children }: Props) {
  useEffect(() => {
    var span = spanRef.current;
    var modal = modalRef.current;
    // When the user clicks on <span> (x), close the modal
    if (span) {
      span.onclick = function () {
        if (modal) {
          modal.style.display = "none";
        }
      };
    }
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event: any) {
      if (event.target === modal) {
        if (modal) {
          modal.style.display = "none";
        }
      }
    };
  });
  return ReactDOM.createPortal(
    <div className="modal" ref={modalRef}>
      <div className="modal-content">
        <span ref={spanRef} className="close">
          &times;
        </span>
        {children}
      </div>
    </div>,
    document.querySelector("#modals_container") as HTMLElement
  );
}
